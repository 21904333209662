export default `
  fragment VehicleHead on VehiclesVehicles {
    id
    headTitle: name
    headSlug: slug
  }

  fragment VehicleDealer on VehiclesVehicles {
    dealer {
      slug
      title
      city
      tel
      email
      street
      postcode
      houseNumber
      website
      logo {
        url
        alternativeText
        name
      }
    }
  }

  fragment Vehicle on VehiclesVehicles {
    ...VehicleDealer
    id
    strapiId: id
    slug
    name
    lowest_price
    comments
    videos
    accessories {
      id
      title
      group
    }

    images(sort: "index:asc") {
      id
      url
    }

    lease {
      price
      formUrl
    }
  }

  fragment VehicleSpecs on VehiclesVehicles {
    id
    energylabel
    licensePlate: license_plate
    make
    model
    type
    odometer
    buildyear
    body
    fuel
    transmission
    color
    powerHp: power_hp
    cylinders
    weight
    doors
    seats
    averageConsumption: average_consumption
    quarterlyRoadTaxesMin: quarterly_road_taxes_min
    quarterlyRoadTaxesMax: quarterly_road_taxes_max
    cylindersCapacity: cylinders_capacity,
    interiorColor: interior_color
    color
    datePart1: date_part_1
  }
`;
