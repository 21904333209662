import React from 'react';
import { gql, useQuery } from '@apollo/client';
import VehicleLayout from '../layouts/vehicle';
import vehicleFragment from '../fragments/vehicleApollo';

const GET_LIVE_VEHICLE = gql`
  query GET_LIVE_VEHICLE($slug: String!) {
    head: vehicleBySlug(slug: $slug) {
      ...VehicleHead
    }

    vehicle: vehicleBySlug(slug: $slug) {
      ...Vehicle
    }

    specs: vehicleBySlug(slug: $slug) {
      ...VehicleSpecs
    }
  }
  ${vehicleFragment}
`;

const VehicleBySlug = ({ params }) => {
  const { loading, error, data } = useQuery(GET_LIVE_VEHICLE, {
    variables: {
      slug: `/${params.slug}`,
    },
  });

  if (loading) {
    return 'Loading...';
  }

  if (error) {
    return 'Something went wrong';
  }

  if (!data) {
    if (!loading) {
      console.warn('not loading and no data in vehicle by slug?');
    }
    return null;
  }

  if (!data.vehicle) {
    return 'no vehicle found';
  }

  return (
    <VehicleLayout
      head={data.head}
      vehicle={data.vehicle}
      specs={data.specs}
      slug={params.slug}
    />
  );
};

export default VehicleBySlug;
